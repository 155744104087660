<template>
    <el-carousel indicator-position="outside" height="400px" :initial-index="imgIndex">
      <el-carousel-item v-for="(src,index) in imgList" :key=index>
        <img :src="src" style="max-width: 100%;max-height: 100%;display: block; margin: 0 auto;" />
      </el-carousel-item>
    </el-carousel>
</template>
 
<script>
export default {
  props: {
    imgList: {
      default: Array
    },
    imgIndex: {
      default: Number
    }
  },
};
</script>
 
<style scoped>
</style>