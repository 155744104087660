<template>
  <a class="course-item shadow" @click="onCourseClick">
    <div :class="{ 'course-isteacher': teacher }">
      <div class="course_info">
        <div class="course_base">
          <div class="course-title" :title="course.title">
            <img
              class="vip f-left"
              v-if="course.is_vip"
              src="@/assets/images/course/vip-index@3x.png"
            />
            <img
              class="tuan f-left"
              v-if="course.is_spell == 1"
              src="@/assets/images/course/tuan@2x.png"
            />
            <img
              class="quan f-left"
              v-if="course.is_has_coupon == 1"
              src="@/assets/images/course/quan@2x.png"
            />
            {{ course.title }}
            <div style="clear: both"></div>
          </div>
          <div class="course-schedule">
            <span
              v-if="
                course.course_type == 2 ||
                course.course_type == 3 ||
                course.course_type == 4 ||
                course.course_type == 7
              "
            >
              <img class="clock" src="@/assets/images/icon_news_time.png" />
              <span>{{ course.start_play }}~{{ course.end_play }}</span>
              <span class="vertical-line">|</span>
            </span>
            <span>{{ "共" + course.total_periods + "个课时" }}</span>
          </div>
          <div class="course-teacher-list">
            <div
              class="course-teacher"
              v-for="tea in course.teachers_list"
              :key="tea.id"
            >
              <img class="course-teacher-avatar" :src="tea.teacher_avatar" />
              <router-link
                v-if="!teacher"
                :to="{ name: 'OtoTeacherShow', params: { id: tea.id } }"
              >
                <div class="course-teacher-name">{{ tea.teacher_name }}</div>
              </router-link>
              <a v-else :href="'/teacher/' + tea.id" target="_blank">
                <div class="course-teacher-name">{{ tea.teacher_name }}</div>
              </a>
            </div>
          </div>
        </div>
        <div class="course_cover">
          <img :src="course.cover" />
          <div class="course_type">
            {{ CouseType.getValue(course.course_type) }}
          </div>
        </div>
      </div>
      <div class="hor-line"></div>
      <div class="clear course-footer">
        <span class="f-left course-sales" v-if="course.course_type == 4"
          >{{ course.brows_num }}人已购买</span
        >
        <span class="f-left course-sales" v-else
          >{{ course.sales_num }}人已购买</span
        >
        <span class="f-right">
          <strike v-if="course.underlined_price">
            <Currency class="currency-mini"></Currency>
            <span>{{ course.underlined_price | price }}</span>
          </strike>
          <span
            class="course-price"
            v-if="course.course_type != 4 && course.price > 0"
          >
            <Currency class="currency"></Currency>
            <span v-if="course.is_spell">{{ course.spell_price | price }}</span>
            <span v-else>{{ course.price | price }}</span>
          </span>
          <span class="course-price" v-else>免费</span>
        </span>
      </div>
      <img
        class="has-buy"
        v-if="course.has_buy"
        src="@/assets/images/course/has-buy@3x.png"
      />
    </div>
  </a>
</template>
<script>
import { CouseType } from "@/utils/enums";

export default {
  name: "CourseItem",
  data() {
    return {
      CouseType,
    };
  },
  props: {
    course: {
      type: Object,
    },
    teacher: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async onCourseClick() {
      if (this.course.course_type == 4) {
        if (!localStorage.studentToken) {
          //未登录
          let timestamp = new Date().getTime();
          localStorage.setItem("start_time", timestamp);
        }

        const cloudClassType = this.course.cloud_class_type;

        if (cloudClassType === "plaso") {
          const routeData = this.$router.resolve({
            name: "CloudClass",
            query: { meetingId: this.course.id },
          });

          window.open(routeData.href, "_blank");
          return;
        }

        let info = await this.$http.get(
          `/api/app/getPcRoomCode/course_id=${this.course.id}/chapter_id=0`
        );

        this.$router.push({
          name: "PublicCoursePlay",
          query: { src: info.chapterInfo.web_url },
        });
        //this.$livePlay(this.course.id, this.course.course_type, 0);
      } else {
        this.$router.push({
          name: "CourseShow",
          params: { id: this.course.id },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.course-item {
  width: 386px;
  height: 237px;
  background-color: white;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 23px 20px;
  position: relative;
  transition: all 0.5s;
  text-align: left;
  cursor: pointer;
}

.course-isteacher {
  border: 1px solid #ccc;

  &:nth-child(3n) {
    margin-right: 27px !important;
  }
}

.course-item:hover {
  transform: scale(1.0078534031413613);
}

.course-item:nth-child(3n) {
  margin-right: 0;
}
.course_info {
  display: flex;
  justify-content: space-between;
  .course_base {
    width: 204px;
  }
  .course_cover {
    width: 133px;
    height: 130px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .course_type {
      position: absolute;
      top: 4px;
      right: 4px;
      font-size: 12px;
      color: #fff;
      background-color: rgba(1, 1, 1, 0.6);
      border-radius: 4px;
      padding: 2px 5px;
    }
  }
}
.course-title {
  font-size: 19px;
  font-weight: 500;
  margin-bottom: 8px;
  height: 52px;
  overflow: hidden;
  font-family: "Microsoft YaHei";
  position: relative;
  a {
    color: #595959;
    font-family: "Microsoft YaHei";
  }
  a:hover {
    color: $primary-color;
  }
  .tuan {
    width: 18px;
    margin-right: 6px;
  }
  .quan {
    width: 18px;
    margin-right: 6px;
  }
  img {
    margin-top: 4px;
  }
}

.course-schedule {
  display: none;
  font-size: 14px;
  color: #969696;
  margin-bottom: 28px;
}

.clock {
  width: 11px;
  height: 11px;
  margin-right: 5px;
}

.vertical-line {
  margin: 0 12px;
}

.course-teacher-list {
  overflow: hidden;
  margin-bottom: 36px;
}

.course-teacher {
  float: left;
  text-align: center;
  width: 56px;
  margin-right: 5px;
}

.course-teacher-avatar {
  width: 25px;
  height: 25px;
  border-radius: 100%;
}

.course-teacher-name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  text-align: center;
  &:hover {
    color: $primary-color;
  }
}

.hor-line {
  height: 1px;
  background-color: #e8e8e8;
  margin-bottom: 25px;
}

.course-sales {
  font-size: 14px;
  color: rgba(140, 140, 140, 1);
}

strike {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
  margin-right: 5px;
}

.course-price {
  font-size: 19px;
  font-weight: 500;
  color: rgba(238, 31, 31, 1);
}

.has-buy {
  position: absolute;
  bottom: 84px;
  right: 13px;
  width: 79px;
}

.vip {
  margin-right: 6px;
  width: 32px;
}

.currency {
  width: 14px;
}

.currency-mini {
  width: 10px;
}
</style>
