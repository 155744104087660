<template>
  <div class="schedule-table">
    <div class="header clear">
      <div class="date-wrap"></div>
      <div class="date-wrap" v-for="(date,index) in setting.date" :key="date">
        <div class="week">{{weeks[index]}}</div>
        <div class="date">{{date}}</div>
      </div>
    </div>
    <div>
      <div v-for="time in setting.time" :key="time" class="row clear">
        <div class="time">{{time}}</div>
        <div v-for="(date,index) in setting.date" :key="date">
          <div
            v-if="weekDateCourses[index]&&weekDateCourses[index][time]"
            :class="'invite-status-'+weekDateCourses[index][time].invite_status.toString()"
          >
            <span v-if="weekDateCourses[index][time].invite_status==3">已过期</span>
            <span v-else-if="weekDateCourses[index][time].invite_status==2">约满</span>
            <span v-else-if="weekDateCourses[index][time].invite_status==1">已预约</span>
            <span
              v-else
              @click="showOtoInfo(weekDateCourses[index][time])"
            >可预约</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="预约确认" :visible.sync="showOtoConfirm" custom-class="oto-confirm" width="600px">
      <div class="content">
        <div class="item">
          <span>预约教师：</span>
          <span>{{currentOtoCourse.teacher_name}}</span>
        </div>
        <div class="item">
          <span>预约时间：</span>
          <span>{{appointInfo.date}}</span>
          <span>{{appointInfo.week}}</span>
          <span>{{appointInfo.time}}</span>
          <span>（{{appointInfo.chapter_interval_time}}分钟）</span>
        </div>
        <div>
          <span>价格：</span>
          <span>{{appointInfo.section_price}}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitOto">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "OtoScheduleTable",
  props: {
    teacherId: {
      type: String,
      default: "0"
    },
    is_next: {
      default: 0
    }
  },
  data() {
    return {
      weeks: {
        0: "周一",
        1: "周二",
        2: "周三",
        3: "周四",
        4: "周五",
        5: "周六",
        6: "周日"
      },
      setting: { date: [], time: [] },
      weekDateCourses: {},
      currentOtoCourse: {},
      appointInfo: {},
      showOtoConfirm: false,
      loading: false
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    async getData() {
      this.setting = await this.$http.get(
        "/api/app/otoCourse/settingDate/" + this.is_next
      );
      let data = await this.$http.post("/api/app/teacher/invite", {
        teacher_id: this.teacherId,
        is_next: this.is_next
      });
      let list = data.otoCourseList;
      let weekDateCourses = {};
      for (let w = 0; w < list.length; w++) {
        weekDateCourses[w] = weekDateCourses[w] || {};
        let dateCourses = list[w];
        dateCourses.forEach(c => {
          let start_play = new Date(c.start_play * 1000).format("hh:mm");
          weekDateCourses[w][start_play] = c;
        });
      }
      this.weekDateCourses = weekDateCourses;
    },
    async showOtoInfo(oto) {
      if (oto.invite_status == -1) {
        await this.$info("请您先登录");
        return;
      }
      if (oto.invite_status != 0) {
        return;
      }
      let data = await this.$http.post("/api/app/oto/inviteConfirm", {
        oto_course_id: oto.oto_course_id
      });
      if (data.code == 203) {
        this.$alert(data.msg, "提示", {
          confirmButtonText: "确定"
        });
        return;
      }
      let date = new Date(oto.start_play * 1000);
      let appointInfo = {};
      appointInfo.date = date.format("yyyy.MM.dd");
      let weekDay = date.getDay();
      if (weekDay == 0) {
        appointInfo.week = "周日";
      } else {
        appointInfo.week = this.weeks[weekDay - 1];
      }
      appointInfo.time = date.format("hh:mm");
      appointInfo.chapter_interval_time = data.chapter_interval_time;
      appointInfo.section_price = data.section_price;
      this.appointInfo = appointInfo;
      this.currentOtoCourse = oto;
      this.showOtoConfirm = true;
    },
    async submitOto() {
      this.showOtoConfirm = false;
      this.$router.push({
        name: "OrderConfirm",
        params: { id: this.currentOtoCourse.oto_course_id },
        query: { type: 1 }
      });
    }
  },
  watch: {
    async teacherId() {
      await this.getData();
    },
    async is_next() {
      await this.getData();
    }
  }
};
</script>
<style lang="scss" scoped>
.schedule-table {
  border: 1px solid #efefef;
  box-sizing: border-box;
  width: 818px;
}
.header {
  .date-wrap {
    width: 102px;
    height: 64px;
    box-sizing: border-box;
    float: left;
    border-right: 2px solid #efefef;
    border-bottom: 1px solid #efefef;
    text-align: center;
    &:last-child {
      border-right: none;
    }
    .week {
      font-size: 14px;
      color: #262626;
      margin-bottom: 3px;
      margin-top: 12px;
    }
    .date {
      font-size: 12px;
      color: #8c8c8c;
    }
  }
}
.row {
  font-size: 14px;
  .time {
    font-size: 14px;
    color: #262626;
    font-weight: 500;
  }
  > div {
    width: 102px;
    height: 42px;
    line-height: 41px;
    box-sizing: border-box;
    float: left;
    border-right: 2px solid #efefef;
    border-bottom: 1px solid #efefef;
    text-align: center;
    &:last-child {
      border-right: none;
    }
  }
  &:last-child {
    > div {
      border-bottom: none;
    }
  }
  .invite-status-1 {
    color: $primary-color;
  }
  .invite-status-3,
  .invite-status-2 {
    background: rgba(240, 240, 240, 1);
    color: #8c8c8c;
  }
  .invite-status-0,
  .invite-status--1 {
    background-color: $primary-color;
    color: white;
    cursor: pointer;
    display: flex;
    span {
      flex-grow: 1;
    }
  }
}
/deep/ .oto-confirm {
  border-radius: 5px;
  .el-dialog__header {
    text-align: center;
  }
  .el-dialog__footer {
    text-align: center;
    .el-button--primary {
      width: 150px;
    }
  }
  .content {
    width: 300px;
    margin: 0 auto;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    .item {
      margin-bottom: 20px;
    }
  }
}
</style>