<template>
  <div class="main-container">
    <div class="question-nav">
      <router-link v-if="lastRoute.name"
                   :to="{name: lastRoute.name}">{{lastRoute.label}}</router-link>
      <span v-else>一对一</span>
      <span class="slash el-icon-arrow-right"> </span>
      <!-- <span class="current">{{teacher.teacher_name||teacher.real_name}}</span> -->
      <span class="current">名师详情</span>
    </div>
    <div class="teacher-intro">
      <div class="clear flex-row">
        <div class="info_bg"></div>
        <img class="avatar f-left"
             :src="teacher.avatar" />
      </div>
      <div :class="isNormal?'normal':''">
        <div v-if="!isNormal">
          <div>
            <span class="title">{{teacher.teacher_name}}</span>
            <span>{{teacher.level_name}}</span>
          </div>
          <div class="small">{{teacher.sex==0?'男':'女'}}，{{teacher.age}}岁</div>
        </div>
        <div v-else>
          <div class="title">{{teacher.real_name}}</div>
        </div>
      </div>
      <span class="collect"
            v-if="flag==1"
            @click="cancelCollect">已关注</span>
      <span class="collect"
            v-else
            @click="collect">+关注</span>
      <div v-if="teacher.tag_content"
           class="clear">
        <div class="tag f-left"
             v-for="(tag,index) in teacher.tag_content"
             :key="index">{{tag}}</div>
      </div>
    </div>
    <div class="teacher-main">
      <ul class="clear tabs">
        <li class="tab-item"
            :class="{'tab-item-current':tab==0}"
            @click="changeTab(0)">
          <span>讲师简介</span>
          <div class="tab-line"></div>
        </li>
        <li class="tab-item"
            :class="{'tab-item-current':tab==1}"
            @click="changeTab(1)">
          <span>主讲课程</span>
          <div class="tab-line"></div>
        </li>
        <li class="tab-item"
            :class="{'tab-item-current':tab==4}"
            @click="changeTab(4)">
          <span>全部回答</span>
          <div class="tab-line"></div>
        </li>
        <li v-if="!isNormal"
            class="tab-item"
            :class="{'tab-item-current':tab==2}"
            @click="changeTab(2)">
          <span>约课课表</span>
          <div class="tab-line"></div>
        </li>
        <li v-if="!isNormal"
            class="tab-item"
            :class="{'tab-item-current':tab==3}"
            @click="changeTab(3)">
          <span>讲师评价</span>
          <div class="tab-line"></div>
        </li>
      </ul>
      <div class="tab-content">
        <div v-show="tab==0">
          <div class="intro-item"
               v-for="(attr,index) in attrs"
               :key="index">
            <label>{{attr.attr_name}}：</label>
            <span>{{attr.attr_value_names}}</span>
          </div>
          <div class="intro-item">
            <label>老师介绍：</label>
            <span>{{intro}}</span>
          </div>
        </div>
        <div v-show="tab==1">
          <div class="course-list">
            <CourseItem v-for="course in courses"
                        :key="course.id"
                        :course="course"></CourseItem>
            <div style="clear:both;"></div>
          </div>
          <Empty v-if="courses.length==0"
                 title="暂无课程安排，敬请期待" />
          <el-pagination background
                         class="course-list-page"
                         @current-change="handleCurrentChange"
                         :current-page="page"
                         :page-size="limit"
                         layout="prev, pager, next, total, jumper"
                         :total="total"
                         v-if="courses.length>0"></el-pagination>
        </div>
        <div v-show="tab==2"
             class="schedule">
          <el-tabs v-model="is_next">
            <el-tab-pane v-for="(value,key) in weekTab"
                         :key="key"
                         :label="value"
                         :name="key">
              <OtoScheduleTable :teacherId="$route.params.id.toString()"
                                :is_next="is_next"></OtoScheduleTable>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div v-show="tab==3">
          <div class="clear comment"
               v-if="false">
            <div class="tag"
                 v-for="tag in tags"
                 :key="tag.id">{{tag.title}}（{{tag.count}}）</div>
          </div>
          <div class="appraise">
            <div class="item clear"
                 v-for="appraise in appraiseList"
                 :key="appraise.id">
              <img class="avatar"
                   :src="appraise.avatar" />
              <div class="f-left right">
                <div class="clear">
                  <span class="user f-left">{{appraise.user_name+" 评价"}}</span>
                  <span class="primary-color f-left">{{appraise.course_time}}</span>
                  <span class="yue f-left">的约课</span>
                  <div class="f-left"
                       style="position:relative;">
                    <el-rate v-model="appraise.grade"
                             class="f-left"></el-rate>
                    <div class="mask"></div>
                  </div>
                </div>
                <div class="clear tags">
                  <div class="tag"
                       v-for="tag in appraise.tags"
                       :key="tag">{{tag}}</div>
                </div>
                <div class="content">{{appraise.content}}</div>
                <div class="time">{{appraise.created_at}}</div>
              </div>
            </div>
            <el-pagination background
                           @current-change="handleAppraiseChange"
                           :current-page="pageAppraise"
                           :page-size="limitAppraise"
                           layout="prev, pager, next, total, jumper"
                           :total="totalAppraise"
                           v-if="appraiseList.length>0"></el-pagination>
          </div>
          <Empty v-if="appraiseList.length==0"
                 title="暂无讲师评价信息哦！" />
        </div>
        <div v-show="tab==4">
          <div class="question-box">
            <div v-for="(item,index) in answer_list"
                 :key="index">
              <div @click="QuestionDetail(item)">
                <div class="question-item">
                  <div class="question-item-left">
                    <img :src="item.avatar" />
                  </div>
                  <div class="question-item-right">
                    <h3 class="question-item-name">
                      {{item.nickname}}
                    </h3>
                    <span class="question-item-created_at">{{item.created_at}}</span>
                  </div>
                  <div class="question-item-operate">
                    <span>
                      <img src="@/assets/images/news/read-count@3x.png" />
                      {{item.browse_number}}
                    </span>
                    <span>
                      <img src="@/assets/images/news/comment@3x.png" />
                      {{item.answer_number}}
                    </span>
                    <span @click.stop="clickLike(item)">
                      <img v-if="!item.is_like"
                           src="@/assets/images/news/dianzan@3x.png" />
                      <img v-else
                           src="@/assets/images/news/dianzan_active.png">
                      {{item.like_number}}
                    </span>
                  </div>
                </div>
                <div class="question_content">
                  <p class="question-item-title">{{item.title}}</p>
                  <div class="question-item-content">
                    <img v-if="item.images" :src="stringToArray(item.images)[0]"
                         @click.stop="showPreview(stringToArray(item.images),index)" />
                    <p>{{item.content}}</p>
                  </div>
                </div>
              </div>
            </div>
            <Empty v-if="answer_list.length==0"
                   class="empty"
                   title="暂无回答！" />
            <el-pagination background
                           @current-change="handleCurrentChangeAnswer"
                           :current-page="answer_page"
                           layout="prev, pager, next, total, jumper"
                           :total="answer_total"
                           v-if="answer_list.length>0"></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="isShowImageDialog"
               @closed="clearImg"
               :modal-append-to-body="false"
               append-to-body>
      <PreviewImg :imgList="prviewList"
                  :imgIndex="1"></PreviewImg>
    </el-dialog>
  </div>
</template>
<script>
import PreviewImg from "../../components/PreviewImg"
import CourseItem from "@/components/CourseItem.vue";
import OtoScheduleTable from "../../components/OtoScheduleTable.vue";

export default {
  name: "LecturerShow",
  components: {
    CourseItem,
    OtoScheduleTable,
    PreviewImg
  },
  data () {
    return {
      id: "",
      teacher: {},
      isNormal: true,
      flag: 2,
      tab: 0,
      attrs: [],
      intro: "",
      page: 1,
      limit: 9,
      total: 0,
      courses: [],
      pageAppraise: 1,
      limitAppraise: 10,
      totalAppraise: 0,
      tags: [],
      appraiseList: [],
      lastRoute: {
        name: this.$route.query.route_name || "Oto",
        label: this.$route.query.route_label || "一对一"
      },
      is_next: 0,
      weekTab: {
        0: "本周课表",
        1: "下周课表"
      },
      answer_list: [],
      answer_page: 1,
      answer_total: 0,
      isShowImageDialog: false,
      prviewList: []
    };
  },
  async created () {
    this.id = this.$route.params.id;
    let data = await this.$http.get("/api/app/teacher/" + this.id);

    this.teacher = data.teacher;
    this.isNormal = !!data.teacher.real_name;
    this.flag = data.flag;

    let dataAttr = await this.$http.get("/api/app/teacher/info/" + this.id);
    dataAttr.attr.forEach(a => {
      let attr_value_names = [];
      a.attr_value.forEach(av => {
        attr_value_names.push(av.attr_value_name);
      });
      a.attr_value_names = attr_value_names.join(",");
    });
    this.attrs = dataAttr.attr;
    this.intro = dataAttr.intro;

    await this.getCourse();
    await this.getAnswerList();

    if (this.isNormal) {
      return;
    }
    await this.getAppraise();
  },
  methods: {
    async collect () {
      await this.$http.get("/api/app/teacher/collect/" + this.id, {}, true);
      this.$message({
        message: "收藏成功",
        type: "success"
      });
      this.flag = 1;
    },
    QuestionDetail (item) {
      this.$router.push({ name: 'QuestionShow', params: { id: item.id } });
    },
    async cancelCollect () {
      await this.$http.get("/api/app/teacher/collect/" + this.id);
      this.$message({
        message: "取消收藏成功",
        type: "success"
      });
      this.flag = 2;
    },
    changeTab (tab) {
      this.tab = tab;
    },
    async getAnswerList () {
      this.$http.get(`/api/app/ask/teacherAnswer`, { id: this.id, page: this.answer_page }).then((res) => {
        this.answer_list = res.list;
        this.answer_total = res.total;
      });
    },
    async getCourse () {
      let courseData = await this.$http.post("/api/app/teacher/mainCourse", {
        teacher_id: this.id,
        limit: this.limit,
        page: this.page
      });
      let courses = courseData.list;
      courses.forEach(c => {
        c.start_play = new Date(c.start_play_date * 1000).format("MM月dd日");
        c.end_play = new Date(c.end_play_date * 1000).format("MM月dd日");
        c.teachers_list = c.teachers_list.splice(0, 3);
      });
      this.courses = courses;
      this.total = courseData.total;
    },
    async handleCurrentChange (page) {
      this.page = page;
      await this.getCourse();
    },
    async handleCurrentChangeAnswer (page) {
      this.answer_page = page;
      await this.getAnswerList();
    },
    getDates (isNext) {
      let date = new Date();
      let weekDay = date.getDay();
      let beginDate = [];
      if (weekDay == 0) {
        beginDate = date.getDate() - 6;
      } else {
        beginDate = date.getDate() - (weekDay - 1);
      }
      if (isNext == 1) {
        beginDate = beginDate + 7;
      }
      let dates = [];
      for (let i = 0; i <= 6; i++) {
        let date = new Date();
        date.setDate(beginDate + i);
        dates.push(date);
      }
      return dates;
    },
    async getAppraise () {
      let data = await this.$http.post(
        "/api/app/teacher/comment?teacher_id=" + this.id,
        { page: this.pageAppraise, limit: this.limitAppraise }
      );
      let tags = data.tag;
      tags = tags.sort((a, b) => b.count - a.count);
      this.tags = tags;
      this.totalAppraise = data.comment.total;
      let appraiseList = data.comment.list;
      appraiseList.forEach(a => {
        if (!a.tag_content) {
          return;
        }
        a.tags = a.tag_content.split(",");
      });
      this.appraiseList = appraiseList;
    },
    async handleAppraiseChange (page) {
      this.pageAppraise = page;
      await this.getAppraise();
    },
    stringToArray (str) {
      if (str == "" || str == null || str == undefined) {
        return [];
      } else {
        let arr = str.split(",");
        return arr;
      }
    },
    clearImg () {
      this.isShowImageDialog = false;
    },
    showPreview (list, index) {
      this.prviewList = list;
      this.isShowImageDialog = true;
    },
    clickLike (item) {
      let params = { ask_id: item.id, comment_id: 0, user_type: 1 };
      this.$pub.clickLike(params).then((res) => {
        if (item.is_like == 0) {
          item.is_like = 1;
          item.like_number++;
        } else {
          item.is_like = 0;
          item.like_number--;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../css/oto-teacher.scss";
</style>
